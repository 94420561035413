import React, { useEffect } from "react";
import WhatsApp from "../assets/icons/whatsapp.png";
import Call from "../assets/icons/call.png";
import Mail from "../assets/icons/mail.png";

function Contacts({ setShowConnectButton }) {
  const handleSendWAMessage = () => {
    window.open(`https://api.whatsapp.com/send?phone=917771999771`, "_blank");
  };
  useEffect(() => {
    setShowConnectButton(false);
  }, []);
  return (
    <div className="py-8 sm:p-16 text-center h-[100vh]">
      <h1 className="text-4xl sm:text-5xl font-medium">Contact Us</h1>
      <div className="grid grid-cols-1 sm:grid-cols-3 p-8 justify-center w-full gap-8">
        <div
          className="w-full rounded-md flex justify-center items-center gap-4"
          onClick={handleSendWAMessage}
        >
          <img src={WhatsApp} alt="whatsapp" width={80} />
          <h1>WhatsApp</h1>
        </div>

        <a href="tel:+917771999771">
          <div
            className="w-full rounded-md flex justify-center items-center gap-4"
            // onClick={handleSendWAMessage}
          >
            <img src={Call} alt="call" width={80} />
            <h1>Call Us</h1>
          </div>
        </a>
        <a
          href={
            "mailto:adv.sameerbaig@gmail.com?body=Hi%20Advocate%20Sameer%20Baig%2C%0A%0AI%20am%20reaching%20out%20to%20you%20for%20discussing%20about%20a%20case.&subject=Discuss%20about%20a%20case"
          }
          target="_top"
          //   type="button"
          // className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          <div
            className="w-full rounded-md flex justify-center items-center gap-4"
            // onClick={handleSendWAMessage}
          >
            <img src={Mail} alt={"mail"} width={80} />
            <h1>Mail Us</h1>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Contacts;
