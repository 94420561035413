import React from "react";
// import Facebook from "../assets/icons/facebook.png";
import Instagram from "../assets/icons/instagram.png";
import X from "../assets/icons/twitter.png";
import Mail from "../assets/icons/mail.png";
import Image from "../assets/images/sb-removebg-preview.png";
import LinkedIn from "../assets/icons/linkedin.png";
const socialLinks = [
  {
    name: "linkedin",
    link: "https://www.linkedin.com/in/sameer-baig-106122324/",
    icom: LinkedIn,
  },
  // {
  //   name: "facebook",
  //   link: "https://www.facebook.com",
  //   icom: Facebook,
  // },

  {
    name: "Instagram",
    link: "https://www.instagram.com/sameer.99_",
    icom: Instagram,
  },
  {
    name: "X",
    link: "https://x.com/sameerbaig_iyc",
    icom: X,
  },
  {
    name: "mail",
    link: "mailto:adv.sameerbaig@gmail.com?body=Hi%20Advocate%20Sameer%20Baig%2C%0A%0AI%20am%20reaching%20out%20to%20you%20for%20discussing%20about%20a%20case.&subject=Discuss%20about%20a%20case",
    icom: Mail,
  },
];

function Intro() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div className="py-8 sm:p-16">
        <h1 className="font-medium text-2xl sm:text-4xl mb-4 text-[#FFA500]">
          Hi, I am Sameer Baig
        </h1>
        <h1 className=" font-semibold text-4xl sm:text-6xl mb-4">
          Advocate, By <br />
          Profession
        </h1>
        <h2 className="text-2xl sm:text-3xl font-light mb-8">
          Professional legal counsel specializing in advocacy, advice, and
          client representation.
        </h2>
        <a href="#about">
          <button className="relative rounded-full bg-[#FFA500] px-4 py-2 font-mono font-bold text-black transition-colors duration-300 ease-linear before:absolute before:right-1/2 before:top-1/2 before:-z-[1] before:h-3/4 before:w-2/3 before:origin-bottom-left before:-translate-y-1/2 before:translate-x-1/2 before:animate-ping before:rounded-full before:bg-[#FFA500] hover:bg-[#FFA500] hover:before:bg-[#FFA500]">
            {"Know More >>"}
          </button>
        </a>
        <div className="my-8 flex gap-4">
          {socialLinks.map((elem) => {
            return (
              <a
                key={elem.name}
                href={elem.link}
                target="_blank"
                type="button"
                className="w-12 h-12"
                rel="noreferrer"
                // className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <img src={elem.icom} alt={elem.name} />
              </a>
            );
          })}
        </div>
      </div>
      <div className="flex justify-center items-center py-8 sm:p-16">
        <img src={Image} alt="sb" className="rounded-full bg-[#989898]" />
      </div>
    </div>
  );
}

export default Intro;
