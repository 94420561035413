import React from "react";
import colorPalette from "../constants/color";

const Footer = () => {
  return (
    <div
      className="w-[-webkit-fill-available] flex py-4 text-center justify-evenly text-xs flex-wrap gap-2"
      style={{
        ...colorPalette.footer,
        borderTop: "1px solid gray",
      }}
    >
      <p className="text-gray-400">
        &copy; 2024 ALL RIGHTS RESERVED BY{" Advocate Sameer Baig "}
      </p>
      <p className="text-gray-400">
        DESIGNED & DEVELOPED BY{" "}
        <a
          href="https://samsinfosoft.com/"
          target="_blank"
          className="text-bold hover:underline target:blank"
          rel="noreferrer"
          style={{ ...colorPalette.footerQuickLinks }}
        >
          SAMS INFOSOFT PVT. LTD.
        </a>
      </p>
    </div>
  );
};

export default Footer;
