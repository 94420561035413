import "./App.css";
// import MuiAlert from "@mui/material/Alert";
// import Snackbar from "@mui/material/Snackbar";
import Navbar from "./components/Navbar";
// import { forwardRef } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import React, { useState } from "react";
import Contacts from "./pages/Contacts";
import Footer from "./components/Footer";
// const Alert = forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

function App() {
  // const loader = useSelector((state) => state.loader.value);
  // const snackbar = useSelector((state) => state.snackbar);
  // const dispatch = useDispatch();

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   dispatch(triggerSnackbar({ show: false }));
  // };
  const [showConnectButton, setShowConnectButton] = useState(true);
  return (
    <React.Fragment>
      {/* {loader ? <Loader /> : null} */}
      <Navbar showConnectButton={showConnectButton} />
      <div
        className="px-8 py-16 sm:px-16"
        style={{ backgroundColor: "black", color: "white" }}
        // style={{ backgroundColor: colorPalette.body.backgroundColor }}
      >
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/contacts"
            element={<Contacts setShowConnectButton={setShowConnectButton} />}
          />
        </Routes>
        {/* <Snackbar
          open={snackbar.show}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={snackbar.type}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar> */}
      </div>
      <Footer />
    </React.Fragment>
  );
}
export default App;
