const colors = {
  body: {
    backgroundColor: "red",
  },
  footer: {
    backgroundColor: "black",
    color: "gray",
  },
  footerQuickLinks: {
    color: "#5569EB",
  },
};

export default colors;
