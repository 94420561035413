import React from "react";
import Intro from "../components/Intro";
import About from "../components/About";
import Practice from "../components/Practice";
import Testimonials from "../components/Testimonials";
import Contact from "../components/Contact";

function Home() {
  return (
    <React.Fragment>
      <Intro />
      <About />
      <Practice />
      <Testimonials />
      <Contact />
    </React.Fragment>
  );
}

export default Home;
