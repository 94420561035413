import React from "react";

const practices = [
  {
    title: "Corporate Law",
    description:
      "Advising businesses on legal matters, compliance, and transactions.",
  },
  {
    title: "Criminal Law",
    description:
      "Expertise in handling criminal cases including defense and prosecution.",
  },
  {
    title: "Family Law",
    description:
      "Specialized in divorce, child custody, and other family-related matters.",
  },
];

function Practice() {
  return (
    <div id="practice" className="py-8 sm:p-16 w-full text-center">
      <h1 className="text-4xl sm:text-5xl font-medium">Area of Practice</h1>
      <div className="py-8 sm:p-8 grid grid-cols-1 sm:grid-cols-3 gap-4">
        {practices.map((practice) => {
          return (
            <div
              key={practice.title}
              className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <h5 className="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">
                {practice.title}
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">
                {practice.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Practice;
